<template>
  <div class="web-framework" @click="onHandleClick">
    <div class="wrap-box">
      <!-- 模拟 -->
      <div class="imitate-box">
        <component :is="useComponent" v-if="!isNavigatorClient"></component>
        <div class="tips" v-else>暂不支持移动端预览哦~</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  defineAsyncComponent,
  getCurrentInstance,
  reactive,
  onBeforeMount,
  onUnmounted,
} from "vue";
const { proxy } = getCurrentInstance();
const mainStore = proxy.$usePiniaModule("mainStore");

const routeParams = computed(() => proxy.$route.query);

// 项目名称（组件）
const projectName = computed(() => routeParams.value.projectName);

// 组件集合
const componentBoxs = reactive({
  nantianJudicial: import("./components/nantian-judicial.vue"),
});
// 使用的组件
const useComponent = computed(() =>
  defineAsyncComponent(() => componentBoxs[projectName.value])
);

const onHandleClick = () => {
  // 跳转demo
  proxy.$router.push("/trialDemo");
};

// 控制框架组件
const controlLayout = (result) => {
  mainStore.showHeaderNavbar = result;
  mainStore.showDownFooter = result;
};

// 是否是app
const isNavigatorClient = computed(() => proxy.$isNavigatorClient());

onUnmounted(() => {
  controlLayout(true);
});
</script>

<style lang="scss" scoped>
.web-framework {
  height: 100%;

  .wrap-box {
    height: 100vh;
    overflow: hidden;

    .imitate-box {
      height: 100%;

      .tips {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
